import { FormControl, FormLabel, Textarea, Button, useToast, Box } from "@chakra-ui/react";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import useUser from '../../lib/useUser';
import { client } from "../../queryClient";

// import apis
import { postComment } from "../../apisntypes/commentapis";
import { IPostComment } from "../../apisntypes/commenttypes";
import { getCommentAlarm } from "../../apisntypes/commentapis";
import dompurify from 'dompurify'; // Import dompurify
import sanitizeHtml from 'sanitize-html';

const MAX_COMMENT_LENGTH = 1200;

const CommentPost = ({note_id, cate_pk, pack_pk, addr, original_creator}) => {
  const [comment, setComment] = useState('');
  const {user, } = useUser();
  const toast = useToast();
  const [imageUrl, setImageUrl] = useState('');

  const { isLoading:commentAlarmLoading, data:commentAlarmData, refetch:commentAlarmRefech } = useQuery(
    [`getAlarms`],
    () => getCommentAlarm(),
    {
      enabled: !!user?.username, // Only run the query if username is available
    }
  );
//===============================post mutation
const postMutation = useMutation(postComment, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "Comment posted",
        position: "bottom-right",
      });
      setComment('');
      client.invalidateQueries(['comments', parseInt(note_id, 10)]);
      client.invalidateQueries(['recentComments', parseInt(note_id, 10)]);
    },
    onError: (error) => {
      toast({
        status: "error",
        title: "Error occurred",
        //description: error.message,
        position: "bottom-right",
      });
    },
  });

  //==============================handle Submit to post
  const handleSubmit = () => {
    if (comment.trim() === '') {
      // Handle empty comment content
      return;
    }
    const sanitizedComment = dompurify.sanitize(comment.trim());
    const sanitizedHtml = sanitizeHtml(sanitizedComment);
    if (sanitizedComment !== comment.trim()) {
      // Handle error case where disallowed tags are detected
      alert('Error: Invalid content detected.'); // Replace with appropriate error handling
      return;
    }
    if (sanitizeHtml !== "") {
      const commentData:IPostComment = {
          creator: user,
          original_creator: original_creator,
          note_pk: note_id,
          cate_pk: cate_pk,
          comment: sanitizedHtml,
          imageUrl:imageUrl,
          pack_pk:pack_pk,
          addr:addr,
          depth:0,
      };

      postMutation.mutate(commentData);
      commentAlarmRefech();
    }
  };

  const encodeHTML = (str) => {
    return str.replace(/&/g, "&amp;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
              .replace(/"/g, "&quot;")
              .replace(/'/g, "&#039;");
  };

  // Handle input change
  const handleInputChange = (e) => {
    const inputText = e.target.value;
    const encodedInputText = encodeHTML(inputText);
    if (encodedInputText.length <= MAX_COMMENT_LENGTH) {
      setComment(encodedInputText);
    } else {
      toast({
        status: "error",
        title: "Character limit exceeded",
        description: `Comment cannot exceed ${MAX_COMMENT_LENGTH} characters.`,
        position: "bottom-right",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  //==========================================
    return (
    <>
    <Box  w="100%">
        <FormControl>
            <FormLabel>Comment</FormLabel>
            <Textarea
            value={comment}
            onChange={handleInputChange}
            placeholder="Enter your comment"
            w="100%"
            maxHeight="150px"
            overflowY="auto"
            />
        </FormControl>
        <FormControl>
            <input type="hidden" name="imageUrl" value="" />
        </FormControl>
        <FormControl>
            <input type="hidden" name="note_pk" value={parseInt(note_id,10)} />
        </FormControl>
        <FormControl>
            <input type="hidden" name="cate_pk" value={parseInt(note_id,10)} />
        </FormControl>
        <FormControl>
            <input type="hidden" name="pack_pk" value={parseInt(pack_pk,10)} />
        </FormControl>
        <FormControl>
            <input type="hidden" name="addr" value={addr} />
        </FormControl>
        <FormControl>
            <input type="hidden" name="depth" value={0} />
        </FormControl>
        <FormControl>
            <input type="hidden" name="original_creator" value={original_creator} />
        </FormControl>
        <Box display="flex" justifyContent="center">
            <Button
                colorScheme="blue"
                onClick={handleSubmit}
                m="2"
                
            >
                Post Comment
            </Button>
        </Box>
    </Box>
    </>
    )
}

export default CommentPost;