import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Radio,
    RadioGroup,
    Spacer,
    Stack,
    Text,
    Textarea,
    useToast,
    VStack,
  } from "@chakra-ui/react";
  // import general
  import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from "@chakra-ui/react";
  import ProtectedPage from "../ProtectedPage";
  import { Link, useNavigate, useParams } from "react-router-dom";
  import { useRef, useState, useEffect } from 'react';
  import { client } from "../../queryClient";
  import { useForm } from "react-hook-form";
  import { useMutation, useQuery } from "@tanstack/react-query";
  // import apis
  import { getNPackAddr, updateNPack } from "../../apisntypes/packapis";
  import { getNPack, deletePack } from "../../apisntypes/packapis";
  // import type
  import { ICategory } from "../../apisntypes/categorytypes";
  import { INPack } from "../../apisntypes/packtypes";
  // import custom
  import { containerStyle } from "../../css/customBox";
import { cusColorScheme } from "../../css/customTheme";
import ShowDate from "../common/ShowDate";
import userEvent from "@testing-library/user-event";
import useUser from "../../lib/useUser";
import { RiOpenaiFill } from "react-icons/ri";

const PackDetail = () => {
  // static
  const {isLoggedIn, user, userLoading} = useUser();
  const {addr} = useParams();
  const [pack_pk, setpack_pk] = useState("0");
  const navigate = useNavigate();  
  const cancelRef = useRef();
  const toast = useToast();
  const titleInputRef = useRef(null);
  //State
  const { isLoading, data, refetch } = useQuery<INPack[]>([`npackitem`, addr], getNPackAddr);
  const [pubOpt, setPubOpt] = useState(""); // Initial option
  const [cateOpt, setCateOpt] = useState("a"); // Initial option
  const [noteOpt, setNoteOpt] = useState("a"); // Initial option
  const [commentOpt, setCommentOpt] = useState("a"); // Initial option
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const onCloseDeleteDialog = () => setIsDeleteDialogOpen(false);

  document.title = "Drimnotes " + (data && data.length > 0 ? data[0].title + data[0].desc : "");
  const [packTitle, setPackTitle] = useState((data && data.length > 0) ? data['title'] : "");
  const [desc, setDesc] = useState((data && data.length > 0) ? data[0]?.desc : "");
  // useform
  const {register, handleSubmit:handleSubmitData, watch, setValue, formState } = useForm({
    defaultValues: {
      pk: pack_pk,
      title:packTitle,
      desc:data && data.length > 0 ? data['desc'] : "",
      pubOpt: pubOpt,
      cateOpt :cateOpt,
      noteOpt : noteOpt,
      commentOpt: commentOpt,
    }
  });

// use mutation
  const deletePackMutation = useMutation((pk:number) => deletePack(pk),{
    onSuccess: (data:INPack) => {
    toast({
        status: "success",
        title: "Category deleted",
        position: "bottom-right",
    });
    navigate("/");
    },
    onError: (err: Error) => {
    toast({
        status: "error",
        title: "Error occurred",
        description: err.message,
        position: "bottom-right",
    });
    },
});

const updatePackMutation = useMutation(
  ({pk, title, desc, pubOpt, cateOpt, noteOpt, commentOpt }:
    {pk: string; title:string; desc:string; pubOpt:string; cateOpt:string; noteOpt:string; commentOpt:string }) => 
  updateNPack(pk, title, desc, pubOpt, cateOpt, noteOpt, commentOpt), 
  {
  onSuccess: (data: INPack) => {
    toast({
      status: "success",
      title: "Category updated",
      position: "bottom-right",
  });
  refetch();
  navigate(`/${addr}/pd`);
  setIsEditing(false);
  },
  onError: (err: Error) => {
  toast({
      status: "error",
      title: "Error occurred",
      description: err.message,
      position: "bottom-right",
  });
  },
});



  // event handlers
  const handlePackTitleChange = (e) => {
    // console.log(e.target.value);
    setPackTitle(e.target.value);
    // console.log('editing');
  }
  const handleDescChange = (e) => {
    setDesc(e.target.value);
    // console.log('editing');
  }
  const handlePublicOptChange = (event) => {
    setPubOpt(event);
    // console.log(event);

  };
  const handleCateOptChange = (event) => {
    setCateOpt(event);
  };
  const handleNoteOptChange = (event) => {
    setNoteOpt(event);
  };
  const handleCommentOptChange = (event) => {
    setCommentOpt(event);
  };
  
  const handleEditing = (data) => {
    setIsEditing(true);
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
  }
  
  const handleSaving = async () => {
    try {
      const formData = {
        pk: pack_pk,
        title:packTitle,
        desc:desc,
        pubOpt: pubOpt,
        cateOpt: cateOpt,
        noteOpt: noteOpt,
        commentOpt: commentOpt, // Accessing 'commentOption' value
      };
      // console.log(formData);
      // Call your mutation to update the data
      await updatePackMutation.mutateAsync(formData);
  
      // Handle success (e.g., show a success toast, navigate, or update state)
      
    } catch (error) {
      // Handle error (e.g., show an error toast or update state)
      toast({
        status: 'error',
        title: 'Error occurred',
        description: error.message,
        position: 'bottom-right',
      });
    }
  };
  
  const handleGoBack = () => {
    navigate("/");
  }
  
  const setCancel = () => {
    setIsEditing(false);
    if (data) {
      setPackTitle(data[0].title);
      setDesc(data[0].desc);
      setPubOpt(data[0].pubOpt);
      setCateOpt(data[0].cateOpt);
      setNoteOpt(data[0].noteOpt);
      setCommentOpt(data[0].commentOpt);
    }
  }

  const openDeleteConfirmation = (pk) => {
    setIsDeleteDialogOpen(true);
  };
  
  const onPackDelete =  (pk) => {
      setIsDeleteDialogOpen(false);
      deletePackMutation.mutate(pk);
      }
    // usequery
  useEffect(() => {
    if ( data ) {
      // console.log(data);
      setpack_pk(data['id']);
      setPackTitle(data['title']);
      setPubOpt(data['pubOpt']);
      setCateOpt(data['cateOpt']);
      setNoteOpt(data['noteOpt']);
      setDesc(data['desc']);
      setCommentOpt(data['commentOpt']);
    }
  }, [ data,isEditing]);

  useEffect(() => {
    // Check if the input field exists
    if (titleInputRef.current) {
      // Focus on the input field
      titleInputRef.current.focus();
    }
  }, []);

    return (
        <>
       
        {!isEditing ?
      // 보여주기
        <Container style={containerStyle} >
          <VStack w="100%" as="form" fontSize={"md"}>
            {/* === title */}
            <HStack justifyContent={"space-between"} w="100%" my="4">
              <Box>
              <Button onClick={handleGoBack} colorScheme={cusColorScheme}>back</Button>
              </Box>
              <Box>
              <Heading textAlign={"center"} mb="2">{packTitle}</Heading>
              </Box>
              <Box>
              <Text></Text>
              </Box>
            </HStack>
            {/* === desc */}
            <Link to={`/${addr}`}>
            <Text>https://drimnotes.com/{addr}</Text>
            </Link>
            <HStack w="100%" h="150px" my="4">
                <Text w="50%" textAlign="center">description</Text>
                <Textarea  
                // bgColor={"blue.900"} 
                w="400px" h="100px" 
                borderRadius="5px" 
                readOnly={true}
                value={desc}
                resize="none" 
                >
                </Textarea>

            </HStack>
          <HStack w="100%" my="2">
            <Text w="50%" textAlign={"center"}> created</Text>
            {data &&  (
              <Text> <ShowDate date={data['created_at']} /> </Text>
            )}
          </HStack>
          <HStack w="100%" my="2">
            <Text w="50%" textAlign={"center"}> updated</Text>
            {data &&  (
              <Text> <ShowDate date={data['updated_at']} /> </Text>
            )}
          </HStack>
            <HStack w="100%"  my="2">
                <Text w="180px" textAlign="center" >
                  <FormLabel as="legend">Display option:</FormLabel>
                </Text>
                  <RadioGroup
                  name="radio-options"
                  value={pubOpt}
                  fontSize={"sm"}
                  color="gray.600"
                  ml="20"
                  style={{ alignItems: 'left' }}
                >
                <HStack direction="row" >
                  <Radio value="a" mr="4">Public</Radio>
                  <Radio value="g" mr="4">Group</Radio>
                  <Radio value="n">Private</Radio>
                </HStack>
              </RadioGroup>
            </HStack>  
            
            <HStack w="100%"  my="2">
                <Text w="180px" textAlign="center"><FormLabel as="legend">Allow post category:</FormLabel></Text>
                  <RadioGroup
                    name="radio-options"
                    value={cateOpt}
                    color="gray.600"
                    ml="20"
                    style={{ alignItems: 'left' }}
                  >
                    <Stack direction="row">
                      <Radio value="a" mr="10">Enable</Radio>
                      <Radio value="n">Disable</Radio>
                    </Stack>
                  </RadioGroup>
            </HStack>
            
            <HStack w="100%"  my="2">
                <Text w="180px" textAlign="center">
                  <FormLabel as="legend">Allow post note:</FormLabel>
                  </Text>
                  <RadioGroup
                    name="radio-options"
                    value={noteOpt}
                    color="gray.600"
                    ml="20"
                    style={{ alignItems: 'left' }}
                  >
                    <Stack direction="row">
                      <Radio value="a" mr="10">Enable</Radio>
                      <Radio value="n">Disable</Radio>
                    </Stack>
                  </RadioGroup>
            </HStack>
            
            <HStack w="100%"  my="2">
                <Text w="180px" textAlign="center">
                  <FormLabel as="legend">Allow post comment:</FormLabel>
                </Text>
                  <RadioGroup
                    name="radio-options"
                    value={commentOpt}
                    color="gray.600"
                    ml="20"
                    style={{ alignItems: 'left' }}
                  >
                    <Stack direction="row">
                      <Radio value="a" mr="10">Enable</Radio>
                      <Radio value="n">Disable</Radio>
                    </Stack>
                  </RadioGroup>
            </HStack>
            <HStack w="100%"  my="4">
              <Text w="180px" color="gray"><RiOpenaiFill />
              </Text>
              <Text color="gray">{data && data['prompt']}</Text>
            </HStack>
            { isLoggedIn && !userLoading && data && user.username === data['creator'].username ?
            <HStack w="100%" justifyContent={"space-between"} my="4">
              <Button 
                 onClick={() => {
                  setIsEditing(true); 
                  window.scrollTo({top:0, behavior:'smooth'});}}
                colorScheme={"blue"} 
                size="lg" 
                w="100%">
                edit
              </Button>
              <Button
                colorScheme={"red"} 
                size="lg" 
                w="100%"
                onClick={() => openDeleteConfirmation(data && data.length > 0 ? data[0].pk : "")}
                >
                Delete 
              </Button>
            </HStack>
          : null }
          </VStack>
        </Container>
        :
        // ==================================================== editing
        <Container style={containerStyle}>
          <Heading textAlign={"center"}  my="4">Pack Detail Editing</Heading>
          <Heading my="4">
            <Input 
            autoFocus
            fontSize={'3xl'}
            name="title"
            {...register("title", {required:true})}
            defaultValue={packTitle}
            value={packTitle}
            ref={titleInputRef}
            onChange={handlePackTitleChange}
            required
            />
            </Heading>
        <VStack 
            as="form" 
            w="100%"
        >
        <FormControl>
          <HStack w="100%" h="150px" my="4">
                <Text w="50%" textAlign="center">description</Text>
                
                <Textarea size="sm" 
                 resize="none" 
                {...register("desc",{required:false})}
                value={desc}
                onChange={handleDescChange}
                >
                  </Textarea>
           </HStack>
          </FormControl>
          <FormControl>
          <HStack w="100%" my="4">
              <Text w="180px" textAlign="center" >
                <FormLabel as="legend">Choose display option:</FormLabel>
              </Text>
            <RadioGroup
              {...register("pubOpt", {required:true})} 
              name="radio-options"
              value={pubOpt}
              onChange={handlePublicOptChange}
              ml="20"
              style={{ alignItems: 'left' }}
            >
              <Stack direction="row">
                <Radio value="a" mr="10">Public</Radio>
                <Radio value="g" mr="10">Group</Radio>
                <Radio value="n">Private</Radio>
              </Stack>
            </RadioGroup>
          </HStack>
          </FormControl>

          <FormControl>
          <HStack w="100%" my="4">
            <Text w="180px" textAlign="center" >
            <FormLabel as="legend">Allow post category:</FormLabel>
            </Text>
            <RadioGroup
              {...register("cateOpt", {required:true})} 
              name="radio-options"
              value={cateOpt}
              onChange={handleCateOptChange}
              ml="20"
              style={{ alignItems: 'left' }}
            >
              <Stack direction="row">
                <Radio value="a" mr="10">Enable</Radio>
                <Radio value="n">Disable</Radio>
              </Stack>
            </RadioGroup>
            </HStack>
          </FormControl>

          <FormControl>
            <HStack w="100%" my="4">
              <Text w="180px">
                <FormLabel as="legend">Allow Post a note:</FormLabel>
              </Text>
              <RadioGroup
                {...register("noteOpt", {required:true})} 
                name="radio-options"
                value={noteOpt}
                onChange={handleNoteOptChange}
                ml="20"
                style={{ alignItems: 'left' }}
              >
                <Stack direction="row">
                  <Radio value="a" mr="10">Enable</Radio>
                  <Radio value="n">Disable</Radio>
                </Stack>
              </RadioGroup>
            </HStack>
          </FormControl>

          <FormControl>
            <HStack w="100%" my="4">
              <Text w="180px">
                <FormLabel as="legend">Allow post a comment:</FormLabel>
              </Text>
            <RadioGroup
              {...register("commentOpt", {required:true})} 
              name="radio-options"
              value={commentOpt}
              onChange={handleCommentOptChange}
              ml="20"
              style={{ alignItems: 'left' }}
            >
              <Stack direction="row">
                <Radio value="a" mr="10">Enable</Radio>
                <Radio value="n">Disable</Radio>
              </Stack>
            </RadioGroup>
            </HStack>
          </FormControl>

            
        </VStack>
          <HStack w="100%" my="4">
            <Button 
                colorScheme={"red"} 
                size="lg" 
                w="100%"
                onClick={() => setCancel()}
              >
                Cancel
            </Button>
            <Button
              colorScheme={"blue"} 
              size="lg" 
              w="100%"
              onClick={() => handleSaving()}
              >
                Save
              </Button>
        </HStack>
      </Container>
       }

       {/* when it is deleted consider notes and categories are present */}
        <AlertDialog
              isOpen={isDeleteDialogOpen}
              leastDestructiveRef={cancelRef}
              // onClose={onCloseDeleteDialog}
              onClose={() => setIsDeleteDialogOpen(false)}
            >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Pack
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete this pack?
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseDeleteDialog}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={() => onPackDelete(pack_pk)} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        </>
    )
}

export default PackDetail;