
import { Container, Box, Button, FormControl, FormLabel, Input, Text, useDisclosure, useToast, HStack, Center,  } from "@chakra-ui/react";
import { 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react';
// import general
import ProtectedPage from "../ProtectedPage";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { client } from "../../queryClient";

import { useState,useRef, useEffect } from "react";
// import apis
import {  getAllCategoriesByAddr,  postCategory } from "../../apisntypes/categoryapis";
import { moveCategory } from "../../apisntypes/commonapis";
import { getNPack, getNPackAddr } from "../../apisntypes/packapis";
// import user
import useUser from "../../lib/useUser";
// import types
import { ICategory } from "../../apisntypes/categorytypes"; 
import { INPack } from "../../apisntypes/packtypes";
// import component
import CategoryM from "./CategoryM";
import CategoryModalRoot from "./CategoryModalRoot";

// import custom
import { boxBlue } from "../../css/customBox";
// import icons
import { RiDragDropLine, RiEditLine, RiAddFill } from "react-icons/ri";
import Categories from "./Categories";

interface IMoveCategoryProps {
  prevPk: number;
  newPk: number;
}
export default function CategoryManager() {
  // static
  const {addr} = useParams();
  const [pack_pk, setpack_pk] = useState("0");
  const inputRef = useRef();
  const {userLoading, isLoggedIn, user} = useUser();
  const [nPackTitle, setNPackTitle] = useState("");
  const [isMoving, setIsMoving] = useState(false);
  const [name, setName] = useState("");
  const {register, handleSubmit, setValue, reset, formState} = useForm<ICategory>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // usequery
  const { isLoading:isLoadingNPack, data:NPackData } = useQuery<INPack[]>([`npackitem`, addr], getNPackAddr);
  const { isLoading, data, refetch } = useQuery<ICategory[]>([`categoriesByAddr`, addr], getAllCategoriesByAddr);
 // dynamic
  useEffect(() => {
    if (!isLoadingNPack ) {
      setNPackTitle(NPackData['title']);
      setpack_pk(NPackData['id']);
    }
  }, [isLoadingNPack, NPackData, pack_pk]);
  
  const total = data?.length;
  const navigate = useNavigate();
  const toast = useToast();
  const addButtonRef = useRef<HTMLButtonElement>(null); // Create a ref for the Add button
  const [prevPk, setPrevPk] = useState();
  const [newPk, setNewPk] = useState();

  // mutation
  const moveCate = useMutation((data: IMoveCategoryProps) => moveCategory(prevPk, newPk), {
    onSuccess: (data: IMoveCategoryProps) => {
    toast({
        status: "success",
        title: "Category updated",
        position: "bottom-right",
    });
    },
    onError: (err: Error) => {
    toast({
        status: "error",
        title: "Error occurred",
        description: err.message,
        position: "bottom-right",
    });
    },
});
  const mutation = useMutation(postCategory, {
    onSuccess: (data:ICategory) => {
        toast({
            status: "success",
            title: "Category posted",
            position: "bottom-right",
        });
        client.invalidateQueries(["categoriesByAddr", addr]);
        setValue("title", "");
        onClose();
    },
});

// func
const onSubmit = (data: ICategory) => {
  mutation.mutate(data, {
    onSuccess: () => {
      if (formState.isValid) {
        onClose(); // Close the modal after successful submission
      }
   },
  });
}

const onMoveClick = (pk) => {
  setPrevPk(pk);
  setIsMoving(!isMoving);
}

const onDropClick = async (pk) => {
  if (prevPk !== pk) {
    setNewPk(pk);
    await moveCate.mutate({prevPk, newPk:pk});
    setIsMoving(false);
    refetch();
  }
}

useEffect(() => {
  if (prevPk !== undefined && newPk !== undefined) {
    moveCate.mutate({ prevPk, newPk });
  }
}, [prevPk, newPk]);

const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
  }
};

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey &&  event.key === "r") {
        // Ctrl+i key combination detected, trigger button click
        addButtonRef.current?.click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const onOpenModal = () => {
    reset(); // Reset the form data
    onOpen();
  };

  return (
    <>
    { isLoggedIn && NPackData  ?
      <Box my={10} p="10" w="100%" >
        <HStack>
            <>
            <Text fontSize={"xl"}>
              {NPackData && (NPackData['creator']?.username === user.username ||  NPackData['cateOpt'] === "a") ?
              <>
                <Link to={`/${addr}`}>
                  {NPackData['title']}
                </Link>
              </>
              : null }
              </Text>
            </>
            
            <Button 
              size="md" 
              rounded="full" 
              onClick={onOpenModal} 
              variant={"ghost"}  
              title="Add title on Root(alt+r)"
              ref={addButtonRef}
            >
              <RiAddFill />
            </Button>
            {/* :null} */}
            { isMoving ?
            <>
              <Button
                size="md" 
                rounded="full" 
                onClick={()=> {onDropClick(pack_pk)} }
                variant={"ghost"}  
                title="Drop category below"
                >
                <RiDragDropLine />
              </Button>    

              <Button
                size="md" 
                rounded="full" 
                onClick={() => {setIsMoving(false)}} 
                variant={"ghost"}  
                >
                cancel
              </Button>
            </>
            : null }

          </HStack>

          {data?.map((cate) => (
            <CategoryM
              key={cate?.pk}
              title={cate?.title}
              creator={cate?.creator}
              upperPk={cate?.upperPk}
              pk={cate?.pk}
              postCount={cate?.postCount}
              cateCount={cate?.cateCount}
              cLength={total}
              depth={cate?.depth}
              sub={cate?.sub}
              addr={cate?.addr}
              pack_pk={parseInt(pack_pk,10)}
              prompt={cate?.prompt}
              updated_at={cate?.updated_at}
              isMoving={isMoving}
              onMoveClick={onMoveClick}
              onDropClick={onDropClick}
            />
            ))}

            {data?.length === 0 ?
            <Center {...boxBlue} w="40%">
                <Text>Click + to add a category!</Text>
            </Center>
            :null}

        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
                <ModalContent>
                    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>Add Title to Root</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody >
                    <FormControl>
                            <FormLabel>Title</FormLabel>
                    <Input 
                      type="text" 
                      autoFocus
                      onKeyDown={handleInputKeyDown}
                      {...register("title", {required:true})}  
                      name="title" 
                      required 
                      />
                    </FormControl>
                    <FormControl>
                        <Input type="hidden" {...register("upperPk", {required:true})} value={0} required />
                    </FormControl>
                    <FormControl>
                        <Input type="hidden" {...register("sub", {required:true})} value={0} required />
                    </FormControl>
                    <FormControl>
                        <Input type="hidden" {...register("depth", {required:true})} value={1} required />
                    </FormControl>
                    <FormControl>
                        <Input type="hidden" {...register("pack_pk", {required:true})} value={NPackData['id']} required />
                    </FormControl>
                    <FormControl>
                        <Input type="hidden" {...register("addr", {required:true})} value={addr} required />
                    </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        
                        <Button variant={"ghost"} colorScheme="blue" mr={3} onClick={onClose}>
                          Close
                        </Button>
                        <Button colorScheme='blue' type="submit" onClick={onClose}>
                          Save
                        </Button>
                    </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>            
          
      </Box>
      :null}
  </>
  );
}