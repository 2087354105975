import { hydrateRoot, createRoot } from 'react-dom/client';
import React from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { RouterProvider } from "react-router-dom";
import router from "./router";
import theme from "./theme";
import { QueryClientProvider } from '@tanstack/react-query';
import Footer from './routes/common/Footer';
import { customTheme } from './css/customTheme';
import { HelmetProvider } from 'react-helmet-async';
import { client } from './queryClient';

const App = () => (
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <ChakraProvider theme={customTheme}>
        <HelmetProvider>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <RouterProvider router={router} />
          <Footer />
        </HelmetProvider>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  createRoot(rootElement).render(<App />);
}

// Add type-safe window property
declare global {
  interface Window {
    snapSaveState: () => {
      __PRELOADED_STATE__: any;
    };
    __PRELOADED_STATE__?: any;
  }
}

if (typeof window !== 'undefined') {
  window.snapSaveState = () => ({
    __PRELOADED_STATE__: window.__PRELOADED_STATE__
  });
}