// import general
import {
    Modal,
    ModalOverlay,
    ModalContent, 
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Textarea,
    Checkbox,
  } from '@chakra-ui/react'
import { Box, Button, FormControl, FormLabel, HStack, Input, VStack, useDisclosure, useToast,  } from "@chakra-ui/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { client } from "../../queryClient";
import  useUser from "../../lib/useUser"
// import apis
import {  postCategory, categoryDelete, updateCategory } from "../../apisntypes/categoryapis";
// import types
import { ICategory, IUpdateCategory } from "../../apisntypes/categorytypes"; 
// import icons
import { RxCornerBottomLeft, RxMove } from "react-icons/rx";
import { RiDragDropLine, RiEditLine, RiAddFill, RiOpenaiFill } from "react-icons/ri";
import {FiMinus} from "react-icons/fi";
import {FaRegTrashAlt, FaPencilAlt, FaPlus} from "react-icons/fa";
import { useQueryClient } from '@tanstack/react-query';
import { createAutoSubCate } from '../../apisntypes/categoryapis';
import ProtectedPage from '../ProtectedPage';

export default function CategoryM ({
    pk,
    title,
    creator,
    upperPk,
    sub,
    depth,
    pack_pk,
    addr,
    postCount,
    cLength,
    prompt,
    isMoving,
    onMoveClick,
    onDropClick,
}:ICategory) {
    // static    
    const { isLoggedIn, user, userLoading } = useUser();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [isAutoSubCateOpen, setIsAutoSubCateOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [movePk, setMovePk] = useState(0);
    const [selectedOption1, setSelectedOption1] = useState(true);
    const [selectedOption2, setSelectedOption2] = useState(true);
    const [selectedOption3, setSelectedOption3] = useState(false);
    const [customPrompt, setCustomPrompt] = useState("");
    const [isSubmitPressed, setIsSubmitPressed] = useState(false);

    const toast = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    // useform
    const {register:registerASC, handleSubmit:handleASC, formState:{errors}} = useForm();
    const {register:registerAdd, handleSubmit:handleAddSubmit,  } = useForm<ICategory>();
    const {register:registerEdit, handleSubmit:handleEditSubmit,  setValue, reset} = useForm<IUpdateCategory>({
        defaultValues: {
            title: title,
        }
    });
    // usemutation
  let providedPrompt = "give me categories by lines only without any other comments when i study given [category]";

    const addCate = useMutation(postCategory, {
        onSuccess: (data:ICategory) => {
            toast({
                status: "success",
                title: "Category posted",
                position: "bottom-right",
            });
            navigate(`/${addr}/cm`);
            setValue("title","");
        client.invalidateQueries(["categoriesByAddr", addr]);

        },
    });

    const editCate = useMutation((data: IUpdateCategory) => updateCategory(data.pk,data.title), {
        onSuccess: (data: IUpdateCategory) => {
        toast({
            status: "success",
            title: "Category updated",
            position: "bottom-right",
        });
            setShowEditModal(false);
            client.invalidateQueries(["categoriesByAddr", addr]);
    },
        onError: (err: Error) => {
        toast({
            status: "error",
            title: "Error occurred",
            description: err.message,
            position: "bottom-right",
        });
        },
    });

    const deleteCate = useMutation((pk:number) => categoryDelete(pk),{
        onSuccess: (data:ICategory) => {
        toast({
            status: "success",
            title: "Category deleted",
            position: "bottom-right",
        });
        client.invalidateQueries(["categoriesByAddr", addr]);
    },
        onError: (err: Error) => {
        toast({
            status: "error",
            title: "Error occurred",
            description: err.message,
            position: "bottom-right",
        });
        },
    });

    const openDeleteModal = () => {
        setIsDeleteModalOpen(true);
      };
      
    const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    };
    
    const handleDelete = (pk: number) => {
    deleteCate.mutate(pk);
    closeDeleteModal();
    };

    const closeAutoSubCate = () => {
        setIsAutoSubCateOpen(false);
    }

    function handleAddModalOpen() {  // 추가 열기
        reset();
        setValue("title","");
        setShowAddModal(true);
      }
    
    function handleAddModalClose() { // 추가 닫기
    setShowAddModal(false);
    }

    function handleEditModalOpen() { //수정 열기
    setValue("title", title);
    setShowEditModal(true);
    }

    function handleEditModalClose() { // 수정 닫기
    setShowEditModal(false);
    client.invalidateQueries(["categoriesByAddr", addr]);
    }

    const handleEditFormSubmit = (data:IUpdateCategory)  => {
        setShowEditModal(false);        
        editCate.mutate(data);
        }

    const handleAddFormSubmit = (data:ICategory)  => {
        addCate.mutate(data);
        setShowAddModal(false);
    }

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleAddSubmit(handleAddFormSubmit)();
        }
      };
      
    const onHandleDropClick = (pk) => {
        queryClient.invalidateQueries(["categoriesByAddr", addr]);
        onDropClick(pk);
    }  
    const onHandleMoveClick = (pk) => {
        setMovePk(pk);
        onMoveClick(pk);
    }

    const handleAutoSubCategory = (pk,addr, pack_pk, upperPk) => { // addr, pack_pk, upperPk, depth, 
        setIsAutoSubCateOpen(true);

    }
    const handleAutoSubCateInputChange = () => {

    }
    const handleAutoSubCateClose = () => {
        setIsAutoSubCateOpen(false);
    }

    const onhandleAutoSubCateClick = async () => {
        setIsSubmitPressed(true);
        try {
            const variables = {
                pk:pk, 
                title:title, 
                addr:addr,
                upperPk: upperPk,
                depth: depth,
                prompt:providedPrompt,
            };
            await createAutoSubCate(variables);
            client.invalidateQueries(["categoriesByAddr", addr]);
            closeAutoSubCate();
        } catch (error) {
            console.log("Error");
        }
    }
  
  const handleAutoCategoriesChange2  = (event) => {
    const value = event.target.checked;
    console.log(value);
    if (value === true) {
      console.log('2');
      setSelectedOption2(true);
      setSelectedOption3(false);
    } else {
      setSelectedOption2(false);
      setSelectedOption3(true);
    }
    
  };
  
  const handleAutoCategoriesChange3 = (event) => {
    const value = event.target.checked;
    if (value === true) {
      setSelectedOption2(false);
      setSelectedOption3(true);
    } else {
      setSelectedOption2(true);
      setSelectedOption3(false)
    }
  };
  const handleCustomPrompt = (event) => {
    const newValue = event.target.value;
    setCustomPrompt(newValue);
  }

    return (
        <ProtectedPage>
        <Box  fontSize={"sm"}>
            <HStack pr="2">
            <Link to={`/${addr}/cn/${pk}`}>
                <HStack >
                    {depth > 0 ? 
                    <Box ml={depth*4}>
                    <RxCornerBottomLeft /> 
                    </Box>
                    : null}
                <Box fontSize={"md"} my="1">
                    {title}
                </Box>
                <Box>
                    { postCount > 0 ? <>({postCount})</> : null }
                </Box> 
                {sub}
                </HStack>
            </Link>

            {!isMoving && user.username ===creator.username ? 
            <>
            <Button size="xs" p="-2" rounded="full" onClick={handleAddModalOpen} variant={"ghost"} title="Add category below"><FaPlus /></Button>    
            <Button size="xs" p="-2" rounded="full" onClick={() => handleAutoSubCategory(pk, addr, pack_pk, upperPk)} variant={"ghost"} title="Add ai categories below"><RiOpenaiFill /></Button>    
            
            <Button size="xs" p="-2" rounded="full" onClick={handleEditModalOpen} variant={"ghost"} title="Edit category"><FaPencilAlt /></Button>
            <Button size="xs" p="-2" rounded="full"  onClick={() => onHandleMoveClick(pk)} variant={"ghost"} title="Move category"><RxMove /></Button>    
            </>
            : null }
            { isMoving && pk !== movePk ? 
            <Button size="xs" p="-2" rounded="full"  onClick={() => onHandleDropClick(pk)} variant={"ghost"} title="Drop category below"><RiDragDropLine /></Button>    
            : null}
            {postCount === 0 &&  sub === 0 && !isMoving && user.username===creator.username ?
            <Button size="xs" p="-2" rounded="full" variant={"ghost"} onClick={openDeleteModal} title="Delete category"><FaRegTrashAlt /></Button>    
            : null
            }
            
            </HStack>  
            {/* ======= add modal */}
            <Modal isOpen={showAddModal} onClose={handleAddModalClose}>
            <ModalOverlay />
                <ModalContent>
                    <Box>
                    <ModalHeader>Add Title to {title}</ModalHeader>
                    <ModalCloseButton />

                    <ModalBody >
                    <FormControl>
                            <FormLabel>Title</FormLabel>
                    <Input 
                        type="text" 
                        autoFocus
                        onKeyDown={handleInputKeyDown}
                        {...registerAdd("title", {required:true})} 
                        title="title" 
                        required 
                        />
                    </FormControl>
                    <FormControl>
                        <Input type="hidden" {...registerAdd("upperPk", {required:true})} value={pk} required />
                    </FormControl>
                    <FormControl>
                        <Input type="hidden" {...registerAdd("sub", {required:true})} value={0} required />
                    </FormControl>
                    <FormControl>
                        <Input type="hidden" {...registerAdd("depth", {required:true})} value={depth+1} required />
                    </FormControl>
                    <FormControl>
                        <Input type="hidden" {...registerAdd("pack_pk", {required:true})} value={pack_pk} required />
                    </FormControl>
                    <FormControl>
                        <Input type="hidden" {...registerAdd("addr", {required:true})} value={addr} required />
                    </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant='ghost' colorScheme='blue' mr={3} onClick={handleAddModalClose}>
                            Close
                        </Button>
                        <Button type="submit" colorScheme='blue' onClick={handleAddSubmit(handleAddFormSubmit)}>
                            Save
                        </Button>
                    </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>

            {/* ======= edit modal */}
            <Modal  isOpen={showEditModal} onClose={handleEditModalClose} >
            <ModalOverlay />
                <ModalContent>
                    <Box as="form"  > 
                    <ModalHeader>Edit Category Element</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                        <FormLabel>{title}</FormLabel>
                            <Input type="text" {...registerEdit("title", { required: true })}  name="title"  required />
                        </FormControl>
                        <FormControl >
                            <Input type="hidden" {...registerEdit("pk", {required: true})} name="pk" value={pk} required />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' colorScheme='blue' mr={3} onClick={handleEditModalClose}>
                            Close
                        </Button>
                        <Button type="submit" colorScheme='blue'
                            onClick={handleEditSubmit(handleEditFormSubmit)}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>

            {/* Delete modal */}
            <Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Category</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                Are you sure you want to delete this category?
                </ModalBody>
                <ModalFooter>
                <Button variant="ghost" onClick={closeDeleteModal}>
                    Cancel
                </Button>
                <Button colorScheme="red" mr={3} onClick={() => handleDelete(pk)}>
                    Delete
                </Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
            {/* ======= ai modal */}
            <Modal isOpen={isAutoSubCateOpen} onClose={closeAutoSubCate}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Prompt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* ============================prompt */}
            <FormControl>
                <Box fontSize={"lg"}>{title}</Box>
              <HStack w="100%" justifyContent={"space-between"} my="4">
                  <Checkbox name="autocate1" isChecked={selectedOption1} readOnly>Catetories by AI</Checkbox>
                  <Checkbox name="autocate1"  isChecked={selectedOption2}  onChange={handleAutoCategoriesChange2}>provided</Checkbox>
                  <Checkbox name="autocate1"  isChecked={selectedOption3} onChange={handleAutoCategoriesChange3}>Custom prompt</Checkbox>
              </HStack>
            </FormControl> 
                {selectedOption1 && selectedOption2 &&
                <Textarea w="100%" h="100px" readOnly>{providedPrompt}</Textarea>
                }
                {selectedOption1 && selectedOption3 && (
                <Textarea w="100%" h="100px" onChange={handleCustomPrompt} required>{customPrompt}</Textarea>
                )}

            {/* ============================prompt */}
          
          </ModalBody>

          <ModalFooter>
            <Button variant={"ghost"} colorScheme="blue" onClick={handleAutoSubCateClose}>Cancel</Button>
            {!isSubmitPressed ?
            <Button colorScheme="blue" mr={3} onClick={onhandleAutoSubCateClick}>
              Submit
            </Button>
            : 
            <Button colorScheme="blue" mr={3}>
                Submit
            </Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
        </Box>
        </ProtectedPage>
    )
}

