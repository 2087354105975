import { Button, Heading, Spacer, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Header from "./routes/common/Header";
import { useEffect } from "react";

export default function NotFound() {

useEffect(() => {
  document.title = "404 Page Not Found";
}, []);

    return (
        <>
        <Header />
        <VStack bg="blue.800" justifyContent={"center"} minH="100vh">
            <Heading>Page not found.</Heading>
            <Text my="6"> It seems that you're lost.</Text>
            <Link to="/">
                <Button colorScheme="cyan">
                    Go home &rarr;
                </Button>
            </Link>
        </VStack>
        </>
    );
}